<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-container class="px-5" fluid>
      <v-row>
        <v-col
          cols="12"
          v-bind="{ md: currentUser.admin ? 4 : 12 }"
          class="pa-2"
        >
          <v-autocomplete
            v-model="draft.country"
            :items="countryOptions"
            label="Nước"
            prepend-inner-icon="mdi-flag"
            @change="onCountryChange"
          >
            <template slot="item" slot-scope="{ item }">
              <span>
                <v-icon :size="12" :color="item.color" class="mr-1">
                  mdi-checkbox-blank-circle
                </v-icon>
                <span>{{ item.text }}</span>
              </span>
            </template>
          </v-autocomplete>
          <v-text-field
            v-model="draft.link"
            label="Link Sản Phẩm"
            background-color="grey lighten-4"
            prepend-inner-icon="mdi-link"
            :rules="[rules.required]"
          />
          <v-row class="ma-0 pa-0" justify="space-between" align="center">
            <v-text-field
              v-model="draft.imgLink"
              label="Link Ảnh"
              prepend-inner-icon="mdi-image"
            />

            <v-btn
              v-tooltip="'Upload Ảnh'"
              small
              outlined
              tile
              color="primary"
              @click="openUploadModal"
              @dblclick.prevent=""
            >
              Upload
            </v-btn>
          </v-row>
          <v-row class="ma-0 pa-0" justify="space-between">
            <v-text-field
              v-model="draft.color"
              label="Màu Sắc"
              prepend-inner-icon="mdi-palette"
            />
            <v-text-field
              v-model="draft.size"
              label="Kích Thước"
              prepend-inner-icon="mdi-resize"
            />
          </v-row>
          <v-text-field
            v-model="draft.brand"
            label="Thương Hiệu"
            prepend-inner-icon="mdi-watermark"
          />
          <v-row class="ma-0 pa-0" justify="space-between">
            <v-text-field
              v-model.number="draft.quantity"
              type="number"
              label="Số Lượng"
              min="1"
              background-color="grey lighten-4"
              prepend-inner-icon="mdi-counter"
              :rules="[rules.required]"
            /> </v-row
          ><v-row class="ma-0 pa-0" justify="space-between">
            <v-text-field
              v-model.number="draft.price"
              label="Giá"
              type="number"
              min="0"
              background-color="grey lighten-4"
              prepend-inner-icon="mdi-currency-usd"
              :rules="[rules.required]"
            />
          </v-row>
          <v-text-field
            v-model.number="draft.offVal"
            type="number"
            min="0"
            max="100"
            label="Discount"
            prepend-inner-icon="mdi-percent-outline"
          />
        </v-col>
        <!-- v-model="draft.xxx" -->
        <v-col cols="12" md="4" class="pa-2" v-show="currentUser.admin">
          <v-row class="ma-0 pa-0" justify="space-between">
            <v-text-field
              v-model.number="draft.surCharge"
              label="SurCharge"
              type="number"
              min="0"
              background-color="grey lighten-4"
              prepend-inner-icon="mdi-currency-usd"
              :rules="[rules.required]"
            />
            <v-text-field
              v-model.number="draft.shipWeb"
              label="ShipWeb"
              type="number"
              min="0"
              background-color="grey lighten-4"
              prepend-inner-icon="mdi-currency-usd"
              :rules="[rules.required]"
            />
          </v-row>
          <v-row class="ma-0 pa-0" justify="space-between">
            <v-text-field
              v-model.number="commission"
              type="number"
              min="0"
              label="Tiền Công"
              background-color="grey lighten-4"
              prepend-inner-icon="mdi-cash-multiple"
              :rules="[rules.required]"
            />
            <v-radio-group v-model="commissionType" row>
              <v-radio label="%" value="1"></v-radio>
              <v-radio label="VND" value="2"></v-radio>
            </v-radio-group>
          </v-row>
          <v-row class="ma-0 pa-0" justify="space-between">
            <v-text-field
              v-model.number="draft.tax"
              label="Thuế"
              min="0"
              max="100"
              prepend-inner-icon="mdi-percent-outline"
            />
            <v-switch v-model="useTax" flat></v-switch>
          </v-row>

          <v-row class="ma-0 pa-0" justify="space-between" align="center">
            <v-text-field
              v-model.number="draft.weight"
              type="number"
              min="0"
              label="Weight"
              prepend-inner-icon="mdi-weight-kilogram"
            />
            <v-btn v-tooltip="'Phí Ship'" small outlined tile color="primary">
              {{ draft.weight * draft.weightRate }} $
            </v-btn>
          </v-row>

          <v-row class="ma-0 pa-0" justify="space-between">
            <v-text-field
              v-model.number="draft.weightRate"
              type="number"
              step="0.1"
              min="0"
              label="Weight Rate"
              prepend-inner-icon="mdi-currency-usd"
            />
          </v-row>
          <v-row class="ma-0 pa-0" justify="space-between">
            <v-text-field
              v-model="draft.totalCommission"
              label="Tổng Tiền Công"
              placeholder="Auto"
              background-color="green lighten-5"
              prepend-inner-icon="mdi-cash-multiple"
            />
          </v-row>
          <!-- <v-row class="ma-0 pa-0" justify="space-between">
            <v-text-field
              :value="draft.weight * draft.weightRate"
              readonly
              label="Phí Ship"
              placeholder="Auto"
              type="number"
              min="0"
              background-color="green lighten-5"
              prepend-inner-icon="mdi-currency-usd"
            />
          </v-row> -->

          <v-row class="ma-0 pa-0" justify="space-between">
            <v-text-field
              v-if="currentUser.admin === 1"
              v-model.number="draft.totalin"
              label="Mua Vào"
              type="number"
              placeholder="Auto"
              background-color="green lighten-5"
              prepend-inner-icon="mdi-cash-multiple"
            />
            <!-- <v-checkbox v-model="draft.inVnd" label="VND"></v-checkbox> -->
          </v-row>
          <v-row class="ma-0 pa-0" justify="space-between">
            <v-text-field
              v-model.number="draft.total"
              label="Bán Ra"
              type="number"
              placeholder="Auto"
              background-color="green lighten-5"
              prepend-inner-icon="mdi-cash-multiple"
            />
            <!-- <v-checkbox v-model="draft.outVnd" label="VND"></v-checkbox> -->
          </v-row>
        </v-col>
        <!-- v-model="draft.xxx" -->
        <v-col cols="12" md="4" class="pa-2" v-show="currentUser.admin">
          <v-menu
            v-model="orderDatePicker"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="draft.orderDate"
                label="Order Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              class="ma-0 pa-0"
              v-model="draft.orderDate"
              @input="orderDatePicker = false"
            ></v-date-picker>
          </v-menu>
          <v-row class="ma-0 pa-0" justify="space-between">
            <v-autocomplete
              v-model="draft.method"
              :items="partnerOptions"
              label="Hình Thức Vận Chuyển"
              prepend-inner-icon="mdi-cart"
            />
          </v-row>
          <v-row class="ma-0 pa-0" justify="space-between">
            <v-autocomplete
              v-model="draft.userId"
              :items="userOptions"
              label="Tài Khoản Mua"
              background-color="grey lighten-4"
              prepend-inner-icon="mdi-account"
              :rules="[rules.required]"
            />
          </v-row>
          <v-row class="ma-0 pa-0" justify="space-between">
            <v-autocomplete
              v-model="draft.sellerId"
              :items="sellerOptions"
              clearable
              label="Nhân Viên Bán"
              prepend-inner-icon="mdi-account-check"
            />
            <v-text-field
              v-model="draft.orderNumber"
              label="Mã Đơn Hàng"
              prepend-inner-icon="mdi-cart-check"
            />
          </v-row>
          <v-row class="ma-0 pa-0" justify="space-between">
            <v-text-field
              v-model.number="draft.transfered"
              type="number"
              min="0"
              label="Đã Chuyển Khoản"
              prepend-inner-icon="mdi-cash-multiple"
            />
            <v-switch
              v-model="fullyTransfered"
              label="Toàn Bộ?"
              flat
            ></v-switch>
          </v-row>
          <v-menu
            v-model="arrivalDatePicker"
            :close-on-content-click="true"
            transition="scale-transition"
            origin="bottom left"
            top
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="draft.arrivalDate"
                label="Arrival Date"
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="arrivalDate"
              class="ma-0 pa-0"
              @input="arrivalDatePicker = false"
            ></v-date-picker>
          </v-menu>
          <v-text-field
            v-model="draft.note"
            label="Note"
            prepend-inner-icon="mdi-note"
          />
          <v-autocomplete
            v-model="draft.status"
            :items="statusOptions"
            label="Trạng Thái"
            prepend-inner-icon="mdi-list-status"
          >
            <template slot="item" slot-scope="{ item }">
              <span>
                <v-icon :size="12" :color="item.color" class="mr-1">
                  mdi-checkbox-blank-circle
                </v-icon>
                <span>{{ item.text }}</span>
              </span>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" class="text-right">
          <v-btn type="submit" depressed color="success" class="mr-2">
            CHẤP NHẬN
          </v-btn>
          <!-- <v-btn color="blue" depressed class="mr-2" @click="compute">
            TẠM TÍNH
          </v-btn> -->
          <v-btn color="grey" text class="mr-0" @click="cancel">
            HỦY BỎ
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { round, debounce } from "lodash";
import extractDomain from "extract-domain";
import computedOrder from "@/models/computedOrder";
import rules from "@/utils/formRules";
import newOrder from "@/models/NewOrder";

export default {
  props: ["order", "isCreate"],
  data: () => ({
    dialog: true,
    rules,
    draft: newOrder(),
    commissionType: "2",
    commission: 50000,
    orderDatePicker: false,
    arrivalDatePicker: false,
    arrivalDate: null,
    useTax: true,
    fullyTransfered: false,
    exchangeRates: [],
    unwatch: null,
    userOptions: [],
    partnerOptions: [],
    sellerOptions: [],
    statusOptions: [
      { text: "Chưa nhận", value: 0, color: "black" },
      { text: "Mới nhận", value: 1, color: "orange" },
      { text: "Cần Chuyển Khoản", value: 2, color: "red" },
      { text: "Đã Mua", value: 3, color: "green" },
      { text: "Hàng Về", value: 4, color: "blue" },
      { text: "Đã Nhận", value: 5, color: "blue darken-3" }
    ],
    countryOptions: [
      { text: "USA", value: 1, color: "green" },
      { text: "UK", value: 2, color: "blue" },
      { text: "SPAIN", value: 3, color: "orange" },
      { text: "KOREA", value: 4, color: "red" },
      { text: "POLAND", value: 5, color: "purple" },
      { text: "CHINA", value: 6, color: "yellow" }
    ],
    uploadWidget: {}
  }),
  computed: {
    exchangeValue() {
      return this.exchangeRates[this.draft.country - 1]?.rate || 0;
    },
    ...mapState({
      currentUser: state => state.login.currentUser
    })
  },
  async created() {
    const userData = await this.fetchOrderCol({
      table: "members",
      colName: "user,id,admin"
    });
    this.userOptions = userData.map(_i => ({
      text: _i.user,
      value: _i.id
    }));
    this.sellerOptions = userData
      .filter(_u => _u.admin)
      .map(_i => ({
        text: _i.user,
        value: _i.id
      }));
    const partnerData = await this.fetchOrderCol({
      table: "partners",
      colName: "name,id"
    });
    this.partnerOptions = [
      { text: "Other", value: 0 },
      ...partnerData.map(_i => ({
        text: _i.name,
        value: _i.id
      }))
    ];
    const exchangeData = await this.fetchExchange();
    this.exchangeRates = exchangeData;
    this.uploadWidget = window.cloudinary.createUploadWidget(
      {
        cloud_name: "dgctdson7",
        upload_preset: "nc3oqwf8",
        sources: ["local", "url", "facebook", "instagram"],
        singleUploadAutoClose: true,
        multiple: false,
        folder: "oliver"
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          this.draft.imgLink = result.info.secure_url;
        }
      }
    );
  },

  methods: {
    openUploadModal() {
      if (this.uploadWidget.open) this.uploadWidget.open();
      else alert("Upload widget is not loaded");
    },

    submit() {
      if (!this.$refs.form.validate()) return false;
      const payload = this.compute();
      this.$emit("submit", payload);
    },
    compute() {
      const computedData = new computedOrder({
        ...this.draft,
        userId: this.draft.userId || this.currentUser.id,
        useTax: this.useTax,
        rate: this.exchangeValue,
        fullyTransfered: this.fullyTransfered,
        commission: this.commission,
        commissionType: this.commissionType
      });
      const { orderId, ...orderData } = computedData;
      return { id: orderId, order: orderData };
    },
    cancel() {
      this.$emit("cancel");
    },
    onCountryChange(val) {
      // UK
      if (val === 1) this.draft.weightRate = 10.0;
      else if (val === 2) this.draft.weightRate = 9.0;
      // SPAIN
      else if (val === 3) this.draft.weightRate = 10.0;
      else if (val === 5) this.draft.weightRate = 45.0;
      else if (val === 6) this.draft.weightRate = 20.0;
      else this.draft.weightRate = 11.0;
      if (val === 1) this.draft.tax = 10;
      else this.draft.tax = 0;
    },
    ...mapActions("order", ["fetchOrderCol", "setEditDialog"]),
    ...mapActions("exchange", ["fetchExchange"])
  },
  watch: {
    order: function(val) {
      if (this.unwatch) this.unwatch();
      this.draft = {
        ...val,
        totalCommission: "",
        totalin: val.inM ? round(val.totalin * val.rate, -3) : "",
        total: val.outM ? round(val.total * val.rate, -3) : ""
      };
      this.commission = val.chargeRawValue;
      if (!val.chargeMoney) {
        this.commissionType = "1";
      } else {
        this.commissionType = "2";
      }
      this.arrivalDate = null;
      this.unwatch = this.$watch("commissionType", val => {
        if (val === "1") this.commission = 5;
        else this.commission = 50000;
      });
    },
    commissionType: function(val) {
      if (this.isCreate)
        if (val === "1") this.commission = 5;
        else this.commission = 50000;
    },
    // "draft.country": function(val) {
    //   // UK
    //   if (val === 2) this.draft.weightRate = 9.0;
    //   // SPAIN
    //   else if (val === 3) this.draft.weightRate = 10.0;
    //   else if (val === 5) this.draft.weightRate = 45.0;
    //   else this.draft.weightRate = 11.0;
    //   if (val === 1) this.draft.tax = 10;
    //   else this.draft.tax = 0;
    // },
    "draft.link": function(val) {
      this.draft.site = extractDomain(val) || val;
    },
    arrivalDate(val) {
      this.draft.arrivalDate = val;
    },
    currentUser(val) {
      draft.userId = val.id;
    }
  }
};
</script>
